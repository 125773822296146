<template>
  <div v-if="templateData != null" :class="{ 'display-inline': displayInline }">
    <div class="template-status-extras" v-if="displayInline == false">
      <div class="template-status-container">
        <div v-if="this.templateStatus" class="template-status">
          {{ this.templateStatus }}
        </div>
        <div v-if="this.templateRejection != 'NONE'" class="rejection-reason">
          {{ this.templateRejection }}
        </div>
      </div>
      <div class="template-details-container">
        <div class="template-category" v-if="templateCategory != null">
          {{ templateCategory }}
        </div>
        <div class="template-language-container" v-if="templateLanguage != null">
          {{ templateLanguage }}
        </div>
      </div>
    </div>
    <div class="template-container-holder">
      <div class="template-container-content">
        <div class="template-container-header" v-if="templateHeader != null">
          <div class="template-container-header-text"
            v-if="templateHeader.format == 'TEXT' && templateHeader.text != null">
            {{ templateHeader.text }}
          </div>
        </div>

        <div class="template-container-body" v-if="templateBody != null">
          <div class="template-container-body-text" v-if="templateBody.text != null">
            {{ templateBody.text }}
          </div>
        </div>

        <div class="template-container-footer" v-if="templateFooter != null">
          <div class="template-container-footer-text" v-if="templateFooter.text != null">
            {{ templateFooter.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FbWaBaTemplateSelectorCmp",
  components: {},
  props: {
    templateData: { type: Object,default: () => undefined },
    templateValues: { type: Object,default: () => undefined },
    displayInline: { type: Boolean,default: () => false }
  },
  watch: {
    templateData() {
      this.parseComponents();
    },
  },
  data() {
    return {
      templateStatus: undefined,
      templateRejection: undefined,
      templateCategory: undefined,
      templateHeader: undefined,
      templateBody: undefined,
      templateFooter: undefined,
      templateLanguage: undefined,

    };
  },
  mounted() {
    this.parseComponents();
  },
  methods: {
    parseComponents() {
      if (this.templateData != null) {
        if (Array.isArray(this.templateData.components)) {
          this.templateHeader = this.templateData.components.find(x => x.type == "HEADER");
          this.templateBody = this.templateData.components.find(x => x.type == "BODY");
          this.templateFooter = this.templateData.components.find(x => x.type == "FOOTER");
        } else {
          this.templateHeader = undefined;
          this.templateBody = undefined;
          this.templateFooter = undefined;
        }
        this.templateLanguage = this.templateData.language;
        this.templateStatus = this.templateData.status;
        this.templateCategory = this.templateData.category;
        this.templateRejection = this.templateData.rejected_reason;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.display-inline {
  .template-container-holder {
    padding: .2rem;

    .template-container-content {
      max-width: 100%
    }
  }
}

.template-status-extras {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  padding: .5rem 1rem;

  .template-details-container,
  .template-status-container {
    display: flex;
    flex: 1 1 auto;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem
  }


}

.template-container-holder {
  --primary: #3b4a54;
  --app-background: #eae6df;

  color: var(--primary);
  background-color: var(--WDS-app-wash, var(--app-background));

  padding: 1rem;
  display: flex;


  --incoming-background: white;
  --primary-strong: #111b21;
  --radius-bubble: 7.5px;
  --message-primary: #111b21;
  --secondary-lighter: #8696a0;

  .template-container-content {
    max-width: 65%;
    background-color: var(--incoming-background);
    padding-bottom: 8px;
    padding-top: 6px;
    padding-left: 9px;
    padding-right: 7px;

    box-sizing: border-box;

    position: relative;
    word-wrap: break-word;
    white-space: pre-wrap;

    position: relative;
    flex: none;
    font-size: 14.2px;
    line-height: 19px;
    color: var(--message-primary);
    border-radius: var(--radius-bubble);




    .template-container-header {
      padding-bottom: 5px;
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
      color: var(--primary-strong);
    }

    .template-container-footer {
      position: relative;
      word-wrap: break-word;
      white-space: pre-wrap;
      padding-top: 6px;
      padding-bottom: 2px;
      line-height: 17px;
      padding-left: 0;
      box-sizing: border-box;
      padding-right: 0;
      color: var(--secondary-lighter);
      font-size: .8125rem;
    }
  }
}
</style>