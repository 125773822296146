<template>
  <div v-if="templateOptions">

    <select :value="selectedTemplateId" :disabled="disabled" @input="changeTemplate($event.target.value)">
      <option v-for="option in templateOptions" :key="option.val" :value="option.val"> {{ option.label }}
      </option>
    </select>
    <div v-if="selectedTemplate">
      <FbWaBaTemplateDisplay :templateData="selectedTemplate?.data"></FbWaBaTemplateDisplay>
    </div>
  </div>
</template>

<script>

import FbWaBaTemplateDisplay from "./FbWaBaTemplateDisplay.vue";

export default {
  name: "FbWaBaTemplateSelectorCmp",
  components: {
    FbWaBaTemplateDisplay
  },
  props: {
    fbWaBAId: { type: String,default: () => undefined },
    modelValue: { type: Object,default: () => undefined },
    disabled: { type: Boolean,default: () => false },
  },
  emits: ['update:modelValue'],
  watch: {
    fbWaBAId() {
      this.updateOptions();
    },
    async modelValue(val) {
      if (val != null) {
        if (typeof val == "object") {
          if (this.templateDataList == null) {
            await this.updateOptions();
          }
          this.selectedTemplateId = this.templateDataList?.find(x => x.data.name == val.name && x.data.language == val.language?.code)?._id;
          this.setSelectedTemplate();
        }
      }
    },
  },
  data() {
    return {
      templateDataList: undefined,
      selectedTemplate: undefined,
      selectedTemplateId: undefined,
      templateOptions: undefined,
    };
  },
  mounted() {
    this.updateOptions().then(() => {
      this.selectedTemplateId = this.templateDataList?.find(x => x.data.name == this.modelValue?.name && x.data.language == this.modelValue?.language?.code)?._id;
      this.setSelectedTemplate();
    });
  },
  methods: {
    setSelectedTemplate() {
      if (this.selectedTemplateId != null) {
        this.selectedTemplate = this.templateDataList?.find(x => x._id == this.selectedTemplateId);
      } else {
        this.selectedTemplate = undefined;
      }
    },
    changeTemplate(val) {
      this.selectedTemplateId = val;
      this.setSelectedTemplate();
      this.emitValue();
    },
    emitValue() {
      this.$emit('update:modelValue',{
        name: this.selectedTemplate?.data?.name,
        language: {
          code: this.selectedTemplate?.data?.language
        }
      });
      return;
    },
    async updateOptions() {
      if (this.fbWaBAId != null) {
        return this.$store.dispatch("fbWaMessageTemplate/getAll",{
          fbWaBAId: this.fbWaBAId
        }).then((arr) => {
          if (arr.data != null && Array.isArray(arr.data)) {
            this.templateDataList = arr.data.filter(x => x.data?.name != null);
            this.templateOptions = this.templateDataList?.map(x => {
              return { label: x.data.name,val: x._id };
            })
          } else {
            this.templateOptions = [];
          }
        });
      } else {
        this.templateOptions = [];
      }
    }
  },
};
</script>
<style lang="scss" scoped></style>