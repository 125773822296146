import axios from "../../../axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule("/api/wa/messages", {
  actions: {
    submit(
      { dispatch },
      { wabaId, fbWaContactId, fbWaMessageId, fbWaPhoneId, data }
    ) {
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/wa/messages/submit`,
          data,
          params: {
            wabaId,
            fbWaContactId,
            fbWaMessageId,
            fbWaPhoneId,
          },
        })
        .then((d) => {
          dispatch("clearCachedData", { item: d.data, key: "WAMESSAGE_ADD" });
          return d.data;
        });
    },
    markAsRead({ dispatch }, { id }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/wa/messages/${id}/read`,
          data: {},
        })
        .then((d) => {
          return d.data;
        });
    },
    submitRead({ dispatch }, { id, wabaId, fbWaContactId, fbWaPhoneId }) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/wa/messages/${id}/submit/read`,
          data: {},
          params: {
            wabaId,
            fbWaContactId,
            fbWaPhoneId,
          },
        })
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBWAMESSAGE",
});

export default module;
